
const countriesCodeSelect = [
  {
    label: "+376 Andorra (AD)",
    value: "+376",
  },
  {
    label: "+971 United Arab Emirates (AE)",
    value: "+971",
  },
  {
    label: "+93 Afghanistan (AF)",
    value: "+93",
  },
  {
    label: "+1-268 Antigua and Barbuda (AG)",
    value: "+1-268",
  },
  {
    label: "+1-264 Anguilla (AI)",
    value: "+1-264",
  },
  {
    label: "+355 Albania (AL)",
    value: "+355",
  },
  {
    label: "+374 Armenia (AM)",
    value: "+374",
  },
  {
    label: "+244 Angola (AO)",
    value: "+244",
  },
  {
    label: "+672 Antarctica (AQ)",
    value: "+672",
  },
  {
    label: "+54 Argentina (AR)",
    value: "+54",
  },
  {
    label: "+1-684 American Samoa (AS)",
    value: "+1-684",
  },
  {
    label: "+43 Austria (AT)",
    value: "+43",
  },
  {
    label: "+61 Australia (AU)",
    value: "+61",
  },
  {
    label: "+297 Aruba (AW)",
    value: "+297",
  },
  {
    label: "+358 Alland Islands (AX)",
    value: "+358",
  },
  {
    label: "+994 Azerbaijan (AZ)",
    value: "+994",
  },
  {
    label: "+387 Bosnia and Herzegovina (BA)",
    value: "+387",
  },
  {
    label: "+1-246 Barbados (BB)",
    value: "+1-246",
  },
  {
    label: "+880 Bangladesh (BD)",
    value: "+880",
  },
  {
    label: "+32 Belgium (BE)",
    value: "+32",
  },
  {
    label: "+226 Burkina Faso (BF)",
    value: "+226",
  },
  {
    label: "+359 Bulgaria (BG)",
    value: "+359",
  },
  {
    label: "+973 Bahrain (BH)",
    value: "+973",
  },
  {
    label: "+257 Burundi (BI)",
    value: "+257",
  },
  {
    label: "+229 Benin (BJ)",
    value: "+229",
  },
  {
    label: "+590 Saint Barthelemy (BL)",
    value: "+590",
  },
  {
    label: "+1-441 Bermuda (BM)",
    value: "+1-441",
  },
  {
    label: "+673 Brunei Darussalam (BN)",
    value: "+673",
  },
  {
    label: "+591 Bolivia (BO)",
    value: "+591",
  },
  {
    label: "+55 Brazil (BR)",
    value: "+55",
  },
  {
    label: "+1-242 Bahamas (BS)",
    value: "+1-242",
  },
  {
    label: "+975 Bhutan (BT)",
    value: "+975",
  },
  {
    label: "+47 Bouvet Island (BV)",
    value: "+47",
  },
  {
    label: "+267 Botswana (BW)",
    value: "+267",
  },
  {
    label: "+375 Belarus (BY)",
    value: "+375",
  },
  {
    label: "+501 Belize (BZ)",
    value: "+501",
  },
  {
    label: "+1 Canada (CA)",
    value: "+1",
  },
  {
    label: "+61 Cocos (Keeling) Islands (CC)",
    value: "+61",
  },
  {
    label: "+243 Congo, Democratic Republic of the (CD)",
    value: "+243",
  },
  {
    label: "+236 Central African Republic (CF)",
    value: "+236",
  },
  {
    label: "+242 Congo, Republic of the (CG)",
    value: "+242",
  },
  {
    label: "+41 Switzerland (CH)",
    value: "+41",
  },
  {
    label: "+225 Cote d'Ivoire (CI)",
    value: "+225",
  },
  {
    label: "+682 Cook Islands (CK)",
    value: "+682",
  },
  {
    label: "+56 Chile (CL)",
    value: "+56",
  },
  {
    label: "+237 Cameroon (CM)",
    value: "+237",
  },
  {
    label: "+86 China (CN)",
    value: "+86",
  },
  {
    label: "+57 Colombia (CO)",
    value: "+57",
  },
  {
    label: "+506 Costa Rica (CR)",
    value: "+506",
  },
  {
    label: "+53 Cuba (CU)",
    value: "+53",
  },
  {
    label: "+238 Cape Verde (CV)",
    value: "+238",
  },
  {
    label: "+599 Curacao (CW)",
    value: "+599",
  },
  {
    label: "+61 Christmas Island (CX)",
    value: "+61",
  },
  {
    label: "+357 Cyprus (CY)",
    value: "+357",
  },
  {
    label: "+420 Czech Republic (CZ)",
    value: "+420",
  },
  {
    label: "+49 Germany (DE)",
    value: "+49",
  },
  {
    label: "+253 Djibouti (DJ)",
    value: "+253",
  },
  {
    label: "+45 Denmark (DK)",
    value: "+45",
  },
  {
    label: "+1-767 Dominica (DM)",
    value: "+1-767",
  },
  {
    label: "+1-809 Dominican Republic (DO)",
    value: "+1-809",
  },
  {
    label: "+213 Algeria (DZ)",
    value: "+213",
  },
  {
    label: "+593 Ecuador (EC)",
    value: "+593",
  },
  {
    label: "+372 Estonia (EE)",
    value: "+372",
  },
  {
    label: "+20 Egypt (EG)",
    value: "+20",
  },
  {
    label: "+212 Western Sahara (EH)",
    value: "+212",
  },
  {
    label: "+291 Eritrea (ER)",
    value: "+291",
  },
  {
    label: "+34 Spain (ES)",
    value: "+34",
  },
  {
    label: "+251 Ethiopia (ET)",
    value: "+251",
  },
  {
    label: "+358 Finland (FI)",
    value: "+358",
  },
  {
    label: "+679 Fiji (FJ)",
    value: "+679",
  },
  {
    label: "+500 Falkland Islands (Malvinas) (FK)",
    value: "+500",
  },
  {
    label: "+691 Micronesia, Federated States of (FM)",
    value: "+691",
  },
  {
    label: "+298 Faroe Islands (FO)",
    value: "+298",
  },
  {
    label: "+33 France (FR)",
    value: "+33",
  },
  {
    label: "+241 Gabon (GA)",
    value: "+241",
  },
  {
    label: "+44 United Kingdom (GB)",
    value: "+44",
  },
  {
    label: "+1-473 Grenada (GD)",
    value: "+1-473",
  },
  {
    label: "+995 Georgia (GE)",
    value: "+995",
  },
  {
    label: "+594 French Guiana (GF)",
    value: "+594",
  },
  {
    label: "+44 Guernsey (GG)",
    value: "+44",
  },
  {
    label: "+233 Ghana (GH)",
    value: "+233",
  },
  {
    label: "+350 Gibraltar (GI)",
    value: "+350",
  },
  {
    label: "+299 Greenland (GL)",
    value: "+299",
  },
  {
    label: "+220 Gambia (GM)",
    value: "+220",
  },
  {
    label: "+224 Guinea (GN)",
    value: "+224",
  },
  {
    label: "+590 Guadeloupe (GP)",
    value: "+590",
  },
  {
    label: "+240 Equatorial Guinea (GQ)",
    value: "+240",
  },
  {
    label: "+30 Greece (GR)",
    value: "+30",
  },
  {
    label: "+500 South Georgia and the South Sandwich Islands (GS)",
    value: "+500",
  },
  {
    label: "+502 Guatemala (GT)",
    value: "+502",
  },
  {
    label: "+1-671 Guam (GU)",
    value: "+1-671",
  },
  {
    label: "+245 Guinea-Bissau (GW)",
    value: "+245",
  },
  {
    label: "+592 Guyana (GY)",
    value: "+592",
  },
  {
    label: "+852 Hong Kong (HK)",
    value: "+852",
  },
  {
    label: "+672 Heard Island and McDonald Islands (HM)",
    value: "+672",
  },
  {
    label: "+504 Honduras (HN)",
    value: "+504",
  },
  {
    label: "+385 Croatia (HR)",
    value: "+385",
  },
  {
    label: "+509 Haiti (HT)",
    value: "+509",
  },
  {
    label: "+36 Hungary (HU)",
    value: "+36",
  },
  {
    label: "+62 Indonesia (ID)",
    value: "+62",
  },
  {
    label: "+353 Ireland (IE)",
    value: "+353",
  },
  {
    label: "+972 Israel (IL)",
    value: "+972",
  },
  {
    label: "+44 Isle of Man (IM)",
    value: "+44",
  },
  {
    label: "+91 India (IN)",
    value: "+91",
  },
  {
    label: "+246 British Indian Ocean Territory (IO)",
    value: "+246",
  },
  {
    label: "+964 Iraq (IQ)",
    value: "+964",
  },
  {
    label: "+98 Iran, Islamic Republic of (IR)",
    value: "+98",
  },
  {
    label: "+354 Iceland (IS)",
    value: "+354",
  },
  {
    label: "+39 Italy (IT)",
    value: "+39",
  },
  {
    label: "+44 Jersey (JE)",
    value: "+44",
  },
  {
    label: "+1-876 Jamaica (JM)",
    value: "+1-876",
  },
  {
    label: "+962 Jordan (JO)",
    value: "+962",
  },
  {
    label: "+81 Japan (JP)",
    value: "+81",
  },
  {
    label: "+254 Kenya (KE)",
    value: "+254",
  },
  {
    label: "+996 Kyrgyzstan (KG)",
    value: "+996",
  },
  {
    label: "+855 Cambodia (KH)",
    value: "+855",
  },
  {
    label: "+686 Kiribati (KI)",
    value: "+686",
  },
  {
    label: "+269 Comoros (KM)",
    value: "+269",
  },
  {
    label: "+1-869 Saint Kitts and Nevis (KN)",
    value: "+1-869",
  },
  {
    label: "+850 Korea, Democratic People's Republic of (KP)",
    value: "+850",
  },
  {
    label: "+82 Korea, Republic of (KR)",
    value: "+82",
  },
  {
    label: "+965 Kuwait (KW)",
    value: "+965",
  },
  {
    label: "+1-345 Cayman Islands (KY)",
    value: "+1-345",
  },
  {
    label: "+7 Kazakhstan (KZ)",
    value: "+7",
  },
  {
    label: "+856 Lao People's Democratic Republic (LA)",
    value: "+856",
  },
  {
    label: "+961 Lebanon (LB)",
    value: "+961",
  },
  {
    label: "+1-758 Saint Lucia (LC)",
    value: "+1-758",
  },
  {
    label: "+423 Liechtenstein (LI)",
    value: "+423",
  },
  {
    label: "+94 Sri Lanka (LK)",
    value: "+94",
  },
  {
    label: "+231 Liberia (LR)",
    value: "+231",
  },
  {
    label: "+266 Lesotho (LS)",
    value: "+266",
  },
  {
    label: "+370 Lithuania (LT)",
    value: "+370",
  },
  {
    label: "+352 Luxembourg (LU)",
    value: "+352",
  },
  {
    label: "+371 Latvia (LV)",
    value: "+371",
  },
  {
    label: "+218 Libya (LY)",
    value: "+218",
  },
  {
    label: "+212 Morocco (MA)",
    value: "+212",
  },
  {
    label: "+377 Monaco (MC)",
    value: "+377",
  },
  {
    label: "+373 Moldova, Republic of (MD)",
    value: "+373",
  },
  {
    label: "+382 Montenegro (ME)",
    value: "+382",
  },
  {
    label: "+590 Saint Martin (French part) (MF)",
    value: "+590",
  },
  {
    label: "+261 Madagascar (MG)",
    value: "+261",
  },
  {
    label: "+692 Marshall Islands (MH)",
    value: "+692",
  },
  {
    label: "+389 Macedonia, the Former Yugoslav Republic of (MK)",
    value: "+389",
  },
  {
    label: "+223 Mali (ML)",
    value: "+223",
  },
  {
    label: "+95 Myanmar (MM)",
    value: "+95",
  },
  {
    label: "+976 Mongolia (MN)",
    value: "+976",
  },
  {
    label: "+853 Macao (MO)",
    value: "+853",
  },
  {
    label: "+1-670 Northern Mariana Islands (MP)",
    value: "+1-670",
  },
  {
    label: "+596 Martinique (MQ)",
    value: "+596",
  },
  {
    label: "+222 Mauritania (MR)",
    value: "+222",
  },
  {
    label: "+1-664 Montserrat (MS)",
    value: "+1-664",
  },
  {
    label: "+356 Malta (MT)",
    value: "+356",
  },
  {
    label: "+230 Mauritius (MU)",
    value: "+230",
  },
  {
    label: "+960 Maldives (MV)",
    value: "+960",
  },
  {
    label: "+265 Malawi (MW)",
    value: "+265",
  },
  {
    label: "+52 Mexico (MX)",
    value: "+52",
  },
  {
    label: "+60 Malaysia (MY)",
    value: "+60",
  },
  {
    label: "+258 Mozambique (MZ)",
    value: "+258",
  },
  {
    label: "+264 Namibia (NA)",
    value: "+264",
  },
  {
    label: "+687 New Caledonia (NC)",
    value: "+687",
  },
  {
    label: "+227 Niger (NE)",
    value: "+227",
  },
  {
    label: "+672 Norfolk Island (NF)",
    value: "+672",
  },
  {
    label: "+234 Nigeria (NG)",
    value: "+234",
  },
  {
    label: "+505 Nicaragua (NI)",
    value: "+505",
  },
  {
    label: "+31 Netherlands (NL)",
    value: "+31",
  },
  {
    label: "+47 Norway (NO)",
    value: "+47",
  },
  {
    label: "+977 Nepal (NP)",
    value: "+977",
  },
  {
    label: "+674 Nauru (NR)",
    value: "+674",
  },
  {
    label: "+683 Niue (NU)",
    value: "+683",
  },
  {
    label: "+64 New Zealand (NZ)",
    value: "+64",
  },
  {
    label: "+968 Oman (OM)",
    value: "+968",
  },
  {
    label: "+507 Panama (PA)",
    value: "+507",
  },
  {
    label: "+51 Peru (PE)",
    value: "+51",
  },
  {
    label: "+689 French Polynesia (PF)",
    value: "+689",
  },
  {
    label: "+675 Papua New Guinea (PG)",
    value: "+675",
  },
  {
    label: "+63 Philippines (PH)",
    value: "+63",
  },
  {
    label: "+92 Pakistan (PK)",
    value: "+92",
  },
  {
    label: "+48 Poland (PL)",
    value: "+48",
  },
  {
    label: "+508 Saint Pierre and Miquelon (PM)",
    value: "+508",
  },
  {
    label: "+870 Pitcairn (PN)",
    value: "+870",
  },
  {
    label: "+1 Puerto Rico (PR)",
    value: "+1",
  },
  {
    label: "+970 Palestine, State of (PS)",
    value: "+970",
  },
  {
    label: "+351 Portugal (PT)",
    value: "+351",
  },
  {
    label: "+680 Palau (PW)",
    value: "+680",
  },
  {
    label: "+595 Paraguay (PY)",
    value: "+595",
  },
  {
    label: "+974 Qatar (QA)",
    value: "+974",
  },
  {
    label: "+262 Reunion (RE)",
    value: "+262",
  },
  {
    label: "+40 Romania (RO)",
    value: "+40",
  },
  {
    label: "+381 Serbia (RS)",
    value: "+381",
  },
  {
    label: "+7 Russian Federation (RU)",
    value: "+7",
  },
  {
    label: "+250 Rwanda (RW)",
    value: "+250",
  },
  {
    label: "+966 Saudi Arabia (SA)",
    value: "+966",
  },
  {
    label: "+677 Solomon Islands (SB)",
    value: "+677",
  },
  {
    label: "+248 Seychelles (SC)",
    value: "+248",
  },
  {
    label: "+249 Sudan (SD)",
    value: "+249",
  },
  {
    label: "+46 Sweden (SE)",
    value: "+46",
  },
  {
    label: "+65 Singapore (SG)",
    value: "+65",
  },
  {
    label: "+290 Saint Helena (SH)",
    value: "+290",
  },
  {
    label: "+386 Slovenia (SI)",
    value: "+386",
  },
  {
    label: "+47 Svalbard and Jan Mayen (SJ)",
    value: "+47",
  },
  {
    label: "+421 Slovakia (SK)",
    value: "+421",
  },
  {
    label: "+232 Sierra Leone (SL)",
    value: "+232",
  },
  {
    label: "+378 San Marino (SM)",
    value: "+378",
  },
  {
    label: "+221 Senegal (SN)",
    value: "+221",
  },
  {
    label: "+252 Somalia (SO)",
    value: "+252",
  },
  {
    label: "+597 Suriname (SR)",
    value: "+597",
  },
  {
    label: "+211 South Sudan (SS)",
    value: "+211",
  },
  {
    label: "+239 Sao Tome and Principe (ST)",
    value: "+239",
  },
  {
    label: "+503 El Salvador (SV)",
    value: "+503",
  },
  {
    label: "+1-721 Sint Maarten (Dutch part) (SX)",
    value: "+1-721",
  },
  {
    label: "+963 Syrian Arab Republic (SY)",
    value: "+963",
  },
  {
    label: "+268 Swaziland (SZ)",
    value: "+268",
  },
  {
    label: "+1-649 Turks and Caicos Islands (TC)",
    value: "+1-649",
  },
  {
    label: "+235 Chad (TD)",
    value: "+235",
  },
  {
    label: "+262 French Southern Territories (TF)",
    value: "+262",
  },
  {
    label: "+228 Togo (TG)",
    value: "+228",
  },
  {
    label: "+66 Thailand (TH)",
    value: "+66",
  },
  {
    label: "+992 Tajikistan (TJ)",
    value: "+992",
  },
  {
    label: "+690 Tokelau (TK)",
    value: "+690",
  },
  {
    label: "+670 Timor-Leste (TL)",
    value: "+670",
  },
  {
    label: "+993 Turkmenistan (TM)",
    value: "+993",
  },
  {
    label: "+216 Tunisia (TN)",
    value: "+216",
  },
  {
    label: "+676 Tonga (TO)",
    value: "+676",
  },
  {
    label: "+90 Turkey (TR)",
    value: "+90",
  },
  {
    label: "+1-868 Trinidad and Tobago (TT)",
    value: "+1-868",
  },
  {
    label: "+688 Tuvalu (TV)",
    value: "+688",
  },
  {
    label: "+886 Taiwan, Republic of China (TW)",
    value: "+886",
  },
  {
    label: "+255 United Republic of Tanzania (TZ)",
    value: "+255",
  },
  {
    label: "+380 Ukraine (UA)",
    value: "+380",
  },
  {
    label: "+256 Uganda (UG)",
    value: "+256",
  },
  {
    label: "+1 United States (US)",
    value: "+1",
  },
  {
    label: "+598 Uruguay (UY)",
    value: "+598",
  },
  {
    label: "+998 Uzbekistan (UZ)",
    value: "+998",
  },
  {
    label: "+379 Holy See (Vatican City State) (VA)",
    value: "+379",
  },
  {
    label: "+1-784 Saint Vincent and the Grenadines (VC)",
    value: "+1-784",
  },
  {
    label: "+58 Venezuela (VE)",
    value: "+58",
  },
  {
    label: "+1-284 British Virgin Islands (VG)",
    value: "+1-284",
  },
  {
    label: "+1-340 US Virgin Islands (VI)",
    value: "+1-340",
  },
  {
    label: "+84 Vietnam (VN)",
    value: "+84",
  },
  {
    label: "+678 Vanuatu (VU)",
    value: "+678",
  },
  {
    label: "+681 Wallis and Futuna (WF)",
    value: "+681",
  },
  {
    label: "+685 Samoa (WS)",
    value: "+685",
  },
  {
    label: "+383 Kosovo (XK)",
    value: "+383",
  },
  {
    label: "+967 Yemen (YE)",
    value: "+967",
  },
  {
    label: "+262 Mayotte (YT)",
    value: "+262",
  },
  {
    label: "+27 South Africa (ZA)",
    value: "+27",
  },
  {
    label: "+260 Zambia (ZM)",
    value: "+260",
  },
  {
    label: "+263 Zimbabwe (ZW)",
    value: "+263",
  },
];
  export default countriesCodeSelect;