import {
  CHANGE_THEME_MODE,
  DASHBOARD,
  DRIVERS_LIST,
  GET_ALL_ADMINS,
  GET_ALL_DRIVERS,
  GET_ALL_NOTIFICATION,
  GET_ALL_REQUEST,
  GET_ALL_USERS,
  UPDATE_BOOKING_AMOUNT,
  UPDATE_DRIVER_PROFILE,
  VIEW_DRIVER_DETAIL,
  VIEW_REQUEST_DETAIL,
  VIEW_USER_DETAIL,
} from "../Constant/constant";

let initialstate = {
  userList: null,
  totalUser: null,
  driverList: null,
  totalDriver: null,
  userDetail: null,
  driverDetail: null,
  reqDetail: null,
  driverData: null,
  totalnotification: null,
  notificationList: null,
  totalAdmins:null,
  allAdmins:null,
  dashboard:null,
  themeMode:true
};
const AdminReducer = (state = initialstate, action) => {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        userList: action?.payload?.customer,
        totalUser: action?.payload?.totalCustomer,
      };
    case GET_ALL_DRIVERS:
      return {
        ...state,
        driverList: action?.payload?.customer,
        totalDriver: action?.payload?.totalCustomer,
      };
    case VIEW_USER_DETAIL:
      return {
        ...state,
        userDetail: action?.payload,
      };
    case VIEW_DRIVER_DETAIL:
      return {
        ...state,
        driverDetail: action?.payload,
      };
    case UPDATE_DRIVER_PROFILE:
      // const newArr = state.driverList.map((driverList) =>
      //   driverList?._id == action?.payload._id ? action?.payload : driverList
      // );
      return {
        ...state,
        driverDetail: action?.payload,
      };
    case GET_ALL_REQUEST:
      return {
        ...state,
        requestList: action?.payload?.customer,
        totalRequest: action?.payload?.totalCustomer,
      };
    case VIEW_REQUEST_DETAIL:
      return {
        ...state,
        reqDetail: action?.payload,
      };
    case DRIVERS_LIST:
      return {
        ...state,
        driverData: action?.payload,
      };
    case UPDATE_BOOKING_AMOUNT:
      return {
        ...state,
        reqDetail: action?.payload,
      };
    case GET_ALL_NOTIFICATION:
      return {
        ...state,
        notificationList: action?.payload?.notifications,
        totalnotification: action?.payload?.totalNotification,
      };
      case GET_ALL_ADMINS:
        return {
          ...state,
          allAdmins: action?.payload?.admins,
          totalAdmins: action?.payload?.totalAdmins,
        };
        case DASHBOARD:
          return {
            ...state,
            dashboard: action?.payload,
          };
          case CHANGE_THEME_MODE:
      return {
        ...state,
        themeMode: action?.payload,
      };
    default:
      return state;
  }
};
export default AdminReducer;
