// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import msgIcon from "../../../images/message_icon.svg";
const firebaseConfig = {
  apiKey: "AIzaSyDW5vHbMqboDfJRDXIOe8WSVL2EDr13t5Y",
  authDomain: "feather-heavy.firebaseapp.com",
  projectId: "feather-heavy",
  storageBucket: "feather-heavy.appspot.com",
  messagingSenderId: "770958782314",
  appId: "1:770958782314:web:ebb9beed5d8acc81783f13",
  measurementId: "G-GZPXYL5N9P"
};

initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: `BBkkBhs6mVgPP6a1K5DA3mGYSZCEV6sfeI1jMXEyvp2K-rpYOm0LyAOYkgfvuJWFaI_OkHccsWuUm1SFE2iIStY`,
  })
    .then((currentToken) => {
      if (currentToken) {
        sessionStorage.setItem('device_token', currentToken);
        console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log(payload,"payloadpayload");
     
      const search = window.location.href;
      console.log(search,'search');
      let isExistHash = search?.split("/")[search?.split("/")?.length -1];

      if(isExistHash?.includes("#")){
        isExistHash=isExistHash?.split("#")[0]
      }

      // if(!['notifications']?.includes(isExistHash)){
        toast(payload?.notification?.title, {
          icon: <img src={msgIcon} width="20" height="20" />,
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "light",
          style: { color: "black" },
        });
    // }     
      resolve(payload);
    });
  });
