export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';
export const  GET_ALL_USERS="GET_ALL_USERS";
export const  GET_ALL_DRIVERS="GET_ALL_DRIVERS";
export const  VIEW_USER_DETAIL="VIEW_USER_DETAIL";
export const UPDATE_PROFILE_DETAIL="UPDATE_PROFILE_DETAIL";
export const UPDATE_DRIVER_PROFILE="UPDATE_DRIVER_PROFILE";
export const VIEW_DRIVER_DETAIL="VIEW_DRIVER_DETAIL";
export const START_LOADING="START_LOADING"; 
export const STOP_LOADING="STOP_LOADING";
export const GET_ALL_REQUEST="GET_ALL_REQUEST";
export const VIEW_REQUEST_DETAIL="VIEW_REQUEST_DETAIL";
export const UPDATE_BOOKING_AMOUNT="UPDATE_BOOKING_AMOUNT";
export const DRIVERS_LIST="DRIVERS_LIST";
export const GET_ALL_NOTIFICATION="GET_ALL_NOTIFICATION";
export const CREATE_ADMIN="CREATE_ADMIN";
export const GET_ALL_ADMINS="GET_ALL_ADMINS";
export const UPDATE_ADMIN="UPDATE_ADMIN";
export const DASHBOARD="DASHBOARD";
export const  CHANGE_THEME_MODE=" CHANGE_THEME_MODE"


