import React from "react";

const Footer = () => {
	var d = new Date();
	return (
		<div className="footer out-footer">
			<div className="copyright">
				<p>©2023 
					
					{" "}Feather Heavy{" "}
					<a target="_blank"  rel="noreferrer">
					 LLC 
					</a>{""}
					. All Rights Reserved
					{/* {d.getFullYear()} */}
				</p>
			</div>
		</div>
	);
};

export default Footer;
 