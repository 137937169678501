import Layout from "../components/layouts/Layout";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { CSVLink } from "react-csv";
import Search from "../components/elements/Search";

const tableData = [];

const headers = [
  { label: "Invoice", key: "invoice" },
  { label: "Customer", key: "customer" },
  { label: "Date", key: "date" },
  { label: "Due Date", key: "duedate" },
  { label: "Amount", key: "amount" },
  { label: "Discount", key: "discount" },
  { label: "Open", key: "open" },
  { label: "Adjustment", key: "adjust" },
  { label: "Status", key: "status" },
];
const csvlink = {
  headers: headers,
  data: tableData,
  filename: "csvfile.csv",
};

const Report = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const recordsPage = 10;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = tableData.slice(firstIndex, lastIndex);
  const npage = Math.ceil(tableData.length / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }
  return (
    <Layout>
      <MainPagetitle
        mainTitle="Reports"
        pageTitle="Generated Report"
        parentTitle="Home"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects manage-client">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Generated Report</h4>
                    <Search setSearch={setSearch}  setPage={setPage}/>
                    {/* <div>
                                            <CSVLink {...csvlink} className="btn btn-primary light btn-sm">
                                                <i className="fa-solid fa-file-excel" /> {" "} 
                                                Export Report
                                            </CSVLink> 
                                        </div> */}
                  </div>
                  <div
                    id="report-tblwrapper"
                    className="dataTables_wrapper no-footer h-fixed-505"
                  >
                    <table
                      id="reports-tbl"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>Invoice #</th>
                          <th>Customer</th>
                          <th>Date</th>
                          <th>Due Date</th>
                          <th>Amount</th>
                          <th>Discount</th>
                          <th>Amount Open</th>
                          <th>Adjustment</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records?.length > 0 ? (
                          records?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <Link to={"#"} className="text-primary">
                                  {item.invoice}
                                </Link>
                              </td>
                              <td>
                                <span>{item.customer}</span>
                              </td>
                              <td>
                                <span>{item.date}</span>
                              </td>
                              <td>
                                <span>{item.duedate}</span>
                              </td>
                              <td>
                                <span className="text-secondary">
                                  {item.amount} $
                                </span>
                              </td>
                              <td>
                                <span className="text-secondary">
                                  {item.discount} $
                                </span>
                              </td>
                              <td>
                                <span className="text-secondary">
                                  {item.open} $
                                </span>
                              </td>
                              <td>
                                <span className="text-secondary">
                                  {item.adjust}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`badge light border-0 ${
                                    item.status === "Active"
                                      ? "badge-success"
                                      : "badge-danger"
                                  }`}
                                >
                                  {item.status}
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <td colspan="10">
                            <p className="no_content_table">No Reports yet</p>
                          </td>
                        )}
                      </tbody>
                    </table>
                    {/* <div className="d-sm-flex text-center justify-content-between align-items-center">
                                            
                                            <div className='dataTables_info'>
                                                Showing {lastIndex-recordsPage + 1} to{" "}                                    
                                                {tableData.length < lastIndex ? tableData.length : lastIndex}
                                                {" "}of {tableData.length} entries
                                            </div>
                                            <div
                                                className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                id="example2_paginate"
                                            >
                                                <Link
                                                    className="paginate_button previous disabled"
                                                    to="#"                                        
                                                    onClick={prePage}
                                                >
                                                    <i className="fa-solid fa-angle-left" />
                                                </Link>
                                                <span>                                      
                                                    {number.map((n , i )=>(
                                                        <Link className={`paginate_button ${currentPage === n ? 'current' :  '' } `} key={i}                                            
                                                            onClick={()=>changeCPage(n)}
                                                        > 
                                                            {n}                                                

                                                        </Link>
                                                    ))}
                                                </span>
                                                <Link
                                                    className="paginate_button next"
                                                    to="#"                                        
                                                    onClick={nextPage}
                                                >
                                                    <i className="fa-solid fa-angle-right" />
                                                </Link>
                                            </div>
                                        </div>  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Report;
