const CustomStyles = (themeMode) => {
  return {
    singleValue: (provided) => ({
      ...provided,
      color: themeMode ? "#6e6e6e" : "white",
    }),
    control: (provided, state) => ({
      ...provided,
      width: 68,
      backgroundColor: themeMode ? "white" : "#1e1e1e",
      border: `1px solid ${themeMode ? "#adb5bd" : "#444444"}`,
      height: "40px",
      borderRadius: "4px",
      boxShadow: state.isFocused ? "1px solid #2e4baa" : "none",
      "&:hover": {
        border: "1px solid #2e4baa",
      },
    }),
    input: (provided) => ({
      ...provided,
      color: themeMode ? "#6e6e6e" : "white",
      margin: "0px",  
      padding: "8px",  
    }),
    menu: (provided) => ({
      ...provided,
      width: 200,
    }),
    indicatorSeparator: () => ({ display: "none" }),
    dropdownIndicator: (provided) => ({
      ...provided,
      width: "15px",  
      height: "20px", 
      padding: -5,
      marginLeft: "50px",
      marginTop:-40,
      color: themeMode ? "#6e6e6e" : "white",

    }),
    clearIndicator: (provided) => ({
      display:"none", 
    }),
  };
};
export default CustomStyles;