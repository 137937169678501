import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import InviteCustomer from "../constant/ModalList";
import EmployeeOffcanvas from "../constant/EmployeeOffcanvas";
import Layout from "../components/layouts/Layout";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useDispatch, useSelector } from "react-redux";
// import { getAnnouncements } from "../../store/actions/AdminAction";
import moment from "moment";
import Search from "../components/elements/Search";
import Placholder from "../../images/placholder-img-admin1.png";
// import AnnouncemetModal from "../components/modals/AnnouncemetModal";
import DeleteModal from "../components/modals/DeleteModal";
import CreateModal from "../components/modals/manage_admin/CreateModal";
import UpdateAdminModal from "../components/modals/manage_admin/UpdateAdminModal";
import { getAdminList } from "../../store/actions/AdminAction";
// import UpdateModal from "../components/modals/UpdateModal";

const ManageAdmins = () => {
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL + "/";
  const limit = 7;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminList = useSelector(
    (state) => state?.adminReducer?.allAdmins
  );
  const totalAdmins = useSelector(
    (state) => state?.adminReducer?.totalAdmins
  );
  console.log(adminList,totalAdmins,"adminList");
  const lastIndex = page * limit;
  const npage = Math.ceil(totalAdmins / limit);
  const number = npage? [...Array(npage + 1).keys()].slice(1):[1];
  const [viewVal, setViewVal] = useState(false);
  const [viewDeleteModal, setViewDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [editAnnouncement, setEditAnnouncement] = useState();
  useEffect(() => {
    dispatch(getAdminList(page, limit, search));
  }, [page, limit, search]);

  function prePage() {
    if (page !== 1) {
      setPage(page - 1);
    }
  }
  function changeCPage(id) {
    setPage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setPage(page + 1);
    }
  }

  const invite = useRef();
  const employe = useRef();
 
  return (
    <Layout>
      <MainPagetitle
        mainTitle="Manage Admin"
        pageTitle={"Manage Admin"}
        parentTitle={"Home"}
      />
  {deleteId && (
          <DeleteModal
            viewVal={viewDeleteModal}
            setShowVal={setViewDeleteModal}
            modalName={"adminDelete"}
            id={deleteId}
          />
        )}
        <UpdateAdminModal viewVal={updateModal} setViewVal={setUpdateModal} editAnnouncement={editAnnouncement} />
      <CreateModal viewVal={viewVal} setViewVal={setViewVal} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Manage Admin</h4>
                    <div className="srearch-btn">
                      <div className="tbl-caption d-flex justify-content-end text-wrap align-items-center pl-0-btn">
                        <button
                          className="btn btn-primary"
                          onClick={() => {setViewVal(true);setEditAnnouncement()}}
                        >
                          Create
                        </button>
                        <div  className="searchbarpublic">
                          <Search setSearch={setSearch} setPage={setPage} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer h-fixed-505"
                  >
                    <table
                      id="empoloyees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Surname</th>
                          <th>Email</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {adminList?.length>0?
                            adminList?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center">
                                {/* <img src={item.image} className="avatar avatar-md rounded-circle" alt="" /> */}
                                <div className="ms-2">
                                  <p className="mb-0 text-start font-w500">
                                    {item?.firstName}
                                  </p>
                                  {/* <span>demo@gmail.com</span> */}
                                </div>
                              </div>
                            </td>
                            <td>
                              <p className="mb-0 font-w500">
                                {" "}
                                {item?.lastName}
                              </p>
                            </td>
                            <td>
                              <p className="mb-0 font-w500">
                                {item.email}{" "}
                              </p>
                            </td>
                            <td>
                              <div className="action-button">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-icon-xxs"
                                  onClick={() => {setEditAnnouncement(item);setUpdateModal(true)}}
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                </button>{" "}
                                <button
                                  type="button"
                                  className="btn btn-danger btn-icon-xxs"
                                  onClick={() => {
                                  setDeleteId(item?._id);
                                  setViewDeleteModal(true);
                                }}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        )):<td colspan="10">
                        <p className="no_content_table">No Admins yet</p>
                        </td>}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing{" "}
                        {totalAdmins == 0 ? 0 : lastIndex - limit + 1} to{" "}
                        {totalAdmins < lastIndex
                          ? totalAdmins
                          : lastIndex}{" "}
                        of {totalAdmins} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number?.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmployeeOffcanvas ref={employe} Title="Add Employee" />
      <InviteCustomer ref={invite} Title="Invite Employee" />
    </Layout>
  );
};

export default ManageAdmins;
