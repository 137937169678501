import { Formik, useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
// import {
//   createAnnouncement,
//   createPublicRoom,
//   getStatics,
//   updateAnnouncement,
// } from "../../../store/actions/AdminAction";
import { toast } from "react-toastify";
import { updateAdmins } from "../../../../store/actions/AdminAction";
const UpdateAdminModal = ({ viewVal, setViewVal, editAnnouncement }) => {
  const dispatch = useDispatch();
  return (
    <Modal
      show={viewVal}
      onHide={() => {
        setViewVal(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete_account_modal"
    >
 
      <Modal.Body>
        <h4 className="text-center mb-0 delete_content">
        Update Announcement
        </h4>

        <div className="card-body">
          <div className="basic-form">
            <Formik
              initialValues={{
                admin_id:editAnnouncement?._id,
                firstName: editAnnouncement?.firstName,
                lastName:editAnnouncement?.lastName,
                phnNumber: editAnnouncement?.phnNumber,
                email:editAnnouncement?.email
              }}
              validate={(values) => {
                const errors = {};
                if (!values.firstName) {
                  errors.firstName = "Required*";
                }
                if (!values.lastName) {
                  errors.lastName = "Required*";
                }
                if (!values.phnNumber) {
                  errors.phnNumber = "Required*";
                }
                if (!values.email) {
                  errors.email = "Required*";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting}) => {
           

                    dispatch(updateAdmins(values))
                setViewVal(false);
                setSubmitting(false);
            
 
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                handleReset,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                  <div className="form-group mb-3 col-md-12">
                      <label>First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        className="form-control"
                        placeholder="Type Here..."
                        onChange={handleChange}
                        value={values.firstName}
                      />
                      {errors.firstName && (
                        <span className="formik-errors">{errors.firstName}</span>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Surname</label>
                      <input
                        type="text"
                        name="lastName"
                        className="form-control"
                        placeholder="Type Here..."
                        onChange={handleChange}
                        value={values.lastName}
                      />
                      {errors.lastName && (
                        <span className="formik-errors">{errors.lastName}</span>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Type Here..."
                        onChange={handleChange}
                        value={values.email}
                      />
                      {errors.email && (
                        <span className="formik-errors">{errors.email}</span>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-12">
                      <label>Phone Number</label>
                      <input
                        type="number"
                        name="phnNumber"
                        className="form-control"
                        placeholder="Type Here..."
                        onChange={handleChange}
                        value={values.phnNumber}
                      />
                      {errors.phnNumber && (
                        <span className="formik-errors">{errors.phnNumber}</span>
                      )}
                    </div>
                  </div>

                  <div className="d-flex justify-content-center delete_acc_btns">
                    <button
                      // onClick={handleSubmit}
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary btn-custom btn-lg  submit_btn confirmation_btn"
                    >
                     Update
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setViewVal(false);
                      }}
                      className="btn btn-primary btn-custom btn-lg submit_btn confirmation_btn"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateAdminModal;
