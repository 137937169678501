import React,{useContext, useEffect} from 'react';
import { ThemeContext } from "../../context/ThemeContext";
import Layout from "../components/layouts/Layout";
import MainPagetitle from '../components/layouts/MainPagetitle';
import CardWidget from '../components/elements/CardWidget';
import ProjectOverviewTab from '../components/elements/ProjectOverviewTab';
import ToDoList from '../components/elements/ToDoList';
import EarningBlog from '../components/elements/EarningBlog';
import ActiveProjects from '../components/elements/ActiveProjects';
import ActiveUserMap from '../components/elements/ActiveUserMap';
import ChatElementBlog from '../components/elements/ChatElementBlog';
import UpcomingBlog from '../components/elements/UpcomingBlog';
import ProjectStatusBlog from '../components/elements/ProjectStatusBlog';
import EmployeesTableList from '../components/elements/EmployeesTableList';
import BestSellerTable from '../components/elements/BestSellerTable';
import { useLocation } from 'react-router-dom';
import { dashboardApi } from '../../store/actions/AdminAction';
import { useDispatch, useSelector } from 'react-redux';
const Dashboard = () =>{
    const dispatch = useDispatch();
    const dashboardData = useSelector((state) => state.adminReducer.dashboard);
    useEffect(() => {
      dispatch(dashboardApi());
    }, []);
  
    return (
        <>	
        <Layout>
        <MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-9 wid-100">
                    <div className="row">
                        <CardWidget  dashboard={dashboardData} />
                        <div className="col-xl-8">
                        <EarningBlog />
                        </div>
                        <div className="col-xl-4">
                            <ToDoList />
                        </div>

                    </div>
                </div>
                <div className="col-xl-3 t-earn">
                <ActiveUserMap />	
                </div>
                {/* <div className="col-xl-6 active-p">
                    <ActiveProjects />
                </div> */}
                {/* <div className="col-xl-6 col-md-6 flag">
                    <ActiveUserMap />		
                </div> */}
                {/* <div className="col-xl-4 col-md-6 chat-map">
                    <ChatElementBlog />
                </div> */}
                {/* <div className="col-xl-5 bst-seller">
                <BestSellerTable />
                </div> */}
                {/* <div className="col-xl-3 col-md-6 up-shd">
                    <UpcomingBlog />
                </div> */}

                <div className="col-xl-3 col-md-6 up-shd">
                    <ProjectStatusBlog  title="Platforms"/>
                </div>
                {/* <div className="col-xl-9 bst-seller">
                    <EmployeesTableList />
                </div> */}
            </div>							
        </div>		
         </Layout>
    </>
    )
}

export default Dashboard;