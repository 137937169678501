import {  Route, Routes} from 'react-router-dom';
import "./other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import Login from './jsx/pages/Login';
import Dashboard from './jsx/pages/Dashboard';
import { ToastContainer } from 'react-toastify';
import UserManagement from './jsx/pages/UserManagement';
import Report from './jsx/pages/Report';
import ManageAdmins from './jsx/pages/ManageAdmins';
import ChangePassword from './jsx/pages/ChangePassword';
import PrivateRoute from './jsx/routes/PrivateRoute';
import UserDetail from './jsx/pages/UserDetail';
import Drivers from './jsx/pages/Drivers';
import DriverDetail from './jsx/pages/DriverDetail';
import Requests from './jsx/pages/Requests';
import RequestDetail from './jsx/pages/RequestDetail';
import Loader from './jsx/components/elements/Loader';
import Notifications from './jsx/pages/Notifications';
import { onMessageListener, requestForToken } from './jsx/components/notifications/Notifications';


function App () {
  requestForToken();

  onMessageListener()
    .then((payload) => {
      console.log(payload,"nnn")
      // setNotification({
      //   title: payload?.notification?.title,
      //   body: payload?.notification?.body,
      // });
    })
    .catch((err) => console.log('failed: ', err));
  return (
    <div className="vh-100">
    <ToastContainer limit={1}  autoClose={500}/>
    <Loader />
      <Routes> 
        <Route  path='/' element={<Login />} />
      <Route element={<PrivateRoute />}>
      <Route path='/change-password' element={<ChangePassword />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/reports' element={<Report />} />
        <Route path='/manage-client' element={<ManageAdmins />} />
        <Route path='/user-management' element={<UserManagement />} />
        <Route path='/user-detail/:id' element={<UserDetail />} />
        <Route path='/drivers' element={<Drivers />} />
        <Route path='/driver-detail/:id' element={<DriverDetail />} />
        <Route path='/request' element={<Requests />} />
        <Route path='/request-detail/:id' element={<RequestDetail />} />
        <Route path='/notifications' element={<Notifications />} />
      </Route>   
      </Routes> 
    </div>
  );
};



export default App; 

