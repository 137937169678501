export const modalTitle = {
  userAccount: "Delete User Account",
  driverAccount: "Delete Driver Account",
};
export const modalBody = {
  userAccount: "Are you sure you want to delete this account?",
  driverAccount: "Are you sure you want to delete this driver account?",
  deactiveAccount:"Are you sure you want to deactivate this driver account?",
  activateAccount:"Are you sure you want to activate this driver account?",
  deactiveUser:"Are you sure you want to deactivate this user account?",
  activateUser:"Are you sure you want to activate this user account?",
  adminDelete:"Are you sure you want to delete this admin?"
};
