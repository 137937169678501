import { toast } from "react-toastify";
import API from "../../services/Api";
import { CHANGE_THEME_MODE, CREATE_ADMIN, DASHBOARD, DRIVERS_LIST, GET_ALL_ADMINS, GET_ALL_DRIVERS, GET_ALL_NOTIFICATION, GET_ALL_REQUEST, GET_ALL_USERS, START_LOADING, STOP_LOADING, UPDATE_ADMIN, UPDATE_BOOKING_AMOUNT, UPDATE_DRIVER_PROFILE, UPDATE_PROFILE_DETAIL, VIEW_DRIVER_DETAIL, VIEW_REQUEST_DETAIL, VIEW_USER_DETAIL } from "../Constant/constant";

export const getAllUsers = (page, limit, search, data) => async (dispatch) => {
  dispatch({ type: START_LOADING, payload: true });
    try {
      const res = await API.get(
        `/getAllUsers?page=${
          page == undefined ? 1 : page
        }&limit=${limit}&search=${search ? search : undefined}`,
        data
      );
      if (res?.data?.success) {
        dispatch({
          type: GET_ALL_USERS,
          payload: res?.data?.data,
        });
        dispatch({ type: STOP_LOADING, payload: false });
      } else {
        toast.error(data?.message);
        dispatch({ type: STOP_LOADING, payload: false });
      }
    } catch (error) {
      toast.error(error);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  };

  export const viewUserDetail = (id) => async (dispatch) => {
    dispatch({ type: START_LOADING, payload: true });
    try {
      const { data } = await API.get(
        `/getUserProfileById?id=${id}`
      );
      if (data?.success) {
        dispatch({
          type: VIEW_USER_DETAIL,
          payload: data.data,
        });
        dispatch({ type: STOP_LOADING, payload: false });
      } else {
        toast.error(data?.message);
        dispatch({ type: STOP_LOADING, payload: false });
      }
    } catch (error) {
      toast.error(error);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  };
  export const updateUserProfile = (data) => async (dispatch) => {
    try {
      const res = await API.post("/updateUserprofile", data);
      if (res?.data.success) {
        dispatch({
          type: UPDATE_PROFILE_DETAIL,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.message)
        return res?.data.success
      } else {
        toast.error(res?.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  export const deleteUserAcc = (id,navigate) => async (dispatch) => {
    dispatch({ type: START_LOADING, payload: true });
    try {
      const response = await API.get(`/deleteUserAccount?id=${id}`);
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        navigate("/user-management")
        dispatch({ type: STOP_LOADING, payload: false });
      } else {
        toast.error(response?.data?.message);
        dispatch({ type: STOP_LOADING, payload: false });
      }
    } catch (error) {
      toast.error(error);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  };
  export const changePassword = (data) => async () => {
    const res = await API.put("/resetPass", data);
    if (res?.data.success ) {
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  };
  // Driver Actions
  export const driversList = (page, limit, search, data) => async (dispatch) => {
    dispatch({ type: START_LOADING, payload: true });
    try {
      const res = await API.get(
        `/getAlldrivers?page=${
          page == undefined ? 1 : page
        }&limit=${limit}&search=${search ? search : undefined}`,
        data
      );
      if (res?.data?.success) {
        dispatch({
          type: GET_ALL_DRIVERS,
          payload: res?.data?.data,
        });
        dispatch({ type: STOP_LOADING, payload: false });
      } else {
        toast.error(data?.message);
        dispatch({ type: STOP_LOADING, payload: false });
      }
    } catch (error) {
      toast.error(error);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  };
  export const viewDriverDetail = (id) => async (dispatch) => {
    dispatch({ type: START_LOADING, payload: true });
    try {
      const { data } = await API.get(
        `/getDriverProfileById?id=${id}`
      );
      if (data?.success) {
        dispatch({
          type: VIEW_DRIVER_DETAIL,
          payload: data.data,
        });
        dispatch({ type: STOP_LOADING, payload: false });
      } else {
        toast.error(data?.message);
        dispatch({ type: STOP_LOADING, payload: false });
      }
    } catch (error) {
      toast.error(error);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  };
  export const updateDriverProfile = (data) => async (dispatch) => {
    try {
      const res = await API.post("/updateDriverProfile", data);
      
      console.log(res?.data?.data,"qqqqqqqqqqqqqqqqqqqqqq=====");
      if (res?.data.success) {
        dispatch({
          type: UPDATE_DRIVER_PROFILE,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.message)
        // dispatch(viewDriverDetail())
       
      } 
      else {
        toast.error(res?.data.message);
      }
     
    } 
    
    catch (error) {
      toast.error(error);
    }
  };
  export const deleteDriverAcc = (id,navigate) => async (dispatch) => {
    dispatch({ type: START_LOADING, payload: true });
    try {
      const response = await API.get(`/deleteDriverAccount?id=${id}`);
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        navigate("/drivers")
        dispatch({ type: STOP_LOADING, payload: false });
      } else {
        toast.error(response?.data?.message);
        dispatch({ type: STOP_LOADING, payload: false });
      }
    } catch (error) {
      toast.error(error);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  };
  export const changePasswordDriver = (data) => async () => {
    const res = await API.put("/resetPassDriver", data);
    if (res?.data.success ) {
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }
  };

  // request Action
  export const requestList = (page, limit, search, data) => async (dispatch) => {
    dispatch({ type: START_LOADING, payload: true });
    try {
      const res = await API.get(
        `/getAllbooking?page=${
          page == undefined ? 1 : page
        }&limit=${limit}&search=${search ? search : undefined}`,
        data
      );
      if (res?.data?.success) {
        dispatch({
          type: GET_ALL_REQUEST,
          payload: res?.data?.data,
        });
        dispatch({ type: STOP_LOADING, payload: false });
      } else {
        toast.error(data?.message);
        dispatch({ type: STOP_LOADING, payload: false });
      }
    } catch (error) {
      toast.error(error);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  };
    export const requestDetail = (id) => async (dispatch) => {
      dispatch({ type: START_LOADING, payload: true });
    try {
      const { data } = await API.get(
        `/getBookingById?id=${id}`
      );
      if (data?.success) {
        dispatch({
          type: VIEW_REQUEST_DETAIL,
          payload: data.data,
        });
        dispatch({ type: STOP_LOADING, payload: false });
      } else {
        toast.error(data?.message);
        dispatch({ type: STOP_LOADING, payload: false });
      }
    } catch (error) {
      toast.error(error);
      dispatch({ type: STOP_LOADING, payload: false });
    }
  };
  export const updateBookingAmount = (data) => async (dispatch) => {
    try {
      if(data?.amount?.trim() == ""){
        return  toast.warning("Enter amount first");
      }
      const res = await API.put("/updateBooking", data);
      if (res?.data.success) {
        dispatch({
          type: UPDATE_BOOKING_AMOUNT,
          payload: res?.data?.data,
        });
        toast.success(res?.data?.message);
        return res?.data.success
      } else {
        toast.error(res?.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  export const driversFetch = (data) => async (dispatch) => {
    try {
      const res = await API.get("/fetchDrivers");
      if (res?.data?.success) {
        dispatch({
          type: DRIVERS_LIST,
          payload: res?.data?.data,
        });
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  export const getAllNotifications = (page, limit, search, data) => async (dispatch) => {
    dispatch({ type: START_LOADING, payload: true });
      try {
        const res = await API.get(
          `/getAllNotification?page=${
            page == undefined ? 1 : page
          }&limit=${limit}&search=${search ? search : undefined}`,
          data
        );
        if (res?.data?.success) {
          dispatch({
            type: GET_ALL_NOTIFICATION,
            payload: res?.data?.data,
          });
          dispatch({ type: STOP_LOADING, payload: false });
        } else {
          toast.error(data?.message);
          dispatch({ type: STOP_LOADING, payload: false });
        }
      } catch (error) {
        toast.error(error);
        dispatch({ type: STOP_LOADING, payload: false });
      }
    };

    export const getAdminList = (page, limit, search) => async (dispatch) => {
      dispatch({ type: START_LOADING, payload: true });
      try {
        const res = await API.get(
          `/getAdmins?page=${
            page == undefined ? 1 : page
          }&limit=${limit}&search=${search ? search : undefined}`
        );
        console.log(res,"reeeeee");
        if (res?.data?.success) {
          dispatch({
            type: GET_ALL_ADMINS,
            payload: res?.data?.data,
          });
          dispatch({ type: STOP_LOADING, payload: false });
        } else {
          toast.error(res?.data?.message);
          dispatch({ type: STOP_LOADING, payload: false });
        }
      } catch (error) {
        toast.error(error);
        dispatch({ type: STOP_LOADING, payload: false });
      }
    };
    export const createAdmin = (data) => async (dispatch) => {
      dispatch({ type: START_LOADING, payload: true });
      try {
        const res = await API.post("/createAdmin", data);
        console.log(res,"resrse");
        if (res?.data?.success) {
          dispatch({
            type: CREATE_ADMIN,
            payload: res?.data?.data,
          });
          dispatch({ type: STOP_LOADING, payload: false });
          dispatch(getAdminList(1, 7, undefined));
        } else {
          toast.error(res?.data?.message);
          dispatch({ type: STOP_LOADING, payload: false });
        }
      } catch (error) {
        toast.error(error);
        dispatch({ type: STOP_LOADING, payload: false });
      }
    };
    export const deleteAdmin = (data) => async (dispatch) => {
      try {
        const res = await API.delete(`/deleteAdmin?id=${data}`);
        if (res?.data.success) {
          dispatch(getAdminList(1, 7, undefined));
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    export const updateAdmins = (data) => async (dispatch) => {
      dispatch({ type: START_LOADING, payload: true });
      try {
        const res = await API.put("/updateAdmin", data);
        console.log(res,"99999");
        if (res?.data?.success) {
          dispatch({
            type: UPDATE_ADMIN,
            payload: res?.data?.data,
          });
          dispatch({ type: STOP_LOADING, payload: false });
          dispatch(getAdminList(1, 7, undefined));
        } else {
          toast.error(res?.data?.message);
          dispatch({ type: STOP_LOADING, payload: false });
        }
      } catch (error) {
        toast.error(error);
        dispatch({ type: STOP_LOADING, payload: false });
      }
    };
    export const dashboardApi = () => async (dispatch) => {
      dispatch({ type: START_LOADING, payload: true });
      try {
        const res = await API.get("/dashboard");
        if (res?.data?.success) {
          dispatch({
            type: DASHBOARD,
            payload: res?.data?.data,
          });
          dispatch({ type: STOP_LOADING, payload: false });
        } else {
          toast.error(res?.data?.message);
          dispatch({ type: STOP_LOADING, payload: false });
        }
      } catch (error) {
        toast.error(error);
        dispatch({ type: STOP_LOADING, payload: false });
      }
    };
    export const changeThemeMode = (themeMode) => async (dispatch) => {
      console.log(themeMode,"themeMode");
      try {
        dispatch({
          type: CHANGE_THEME_MODE,
          payload: themeMode,
        });
      } catch (error) {
        console.log(error);
      }
    };