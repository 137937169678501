import React, { useState, useRef, useEffect } from "react";
import Layout from "../components/layouts/Layout";
import { Link, useNavigate } from "react-router-dom";
import InviteCustomer from "../constant/ModalList";
import EmployeeOffcanvas from "../constant/EmployeeOffcanvas";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useDispatch, useSelector } from "react-redux";
import { driversList, updateDriverProfile } from "../../store/actions/AdminAction";
import moment from "moment";
import Search from "../components/elements/Search";
import Placholder from "../../images/placholder-img-admin1.png";
import { toast } from "react-toastify";
import ReactStars from "react-rating-stars-component";
const Drivers = () => {
  const IMG_URL = process.env.REACT_APP_FILE_BASE_URL;
  const limit = 7;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allDrivers = useSelector((state) => state?.adminReducer?.driverList);
  const totalDriver = useSelector((state) => state?.adminReducer?.totalDriver);
  const lastIndex = page * limit;
  const npage = Math.ceil(totalDriver / limit);
  const number = npage ? [...Array(npage + 1).keys()]?.slice(1) : [1];

  useEffect(() => {
    dispatch(driversList(page, limit, search));
  }, [page, limit, search]);

  function prePage() {
    if (page !== 1) {
      setPage(page - 1);
    }
  }
  function changeCPage(id) {
    setPage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setPage(page + 1);
    }
  }

  const invite = useRef();
  const employe = useRef();
  console.log(allDrivers,"allDrivers");
  return (
    <Layout>
      <MainPagetitle
        mainTitle="Drivers"
        pageTitle={"Drivers"}
        parentTitle={"Home"}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Drivers</h4>
                    <Search setSearch={setSearch} setPage={setPage} />
                  </div>
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer h-fixed-505"
                  >
                    <table
                      id="empoloyees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>S.N.</th>
                          <th>User Name</th>
                          <th>Email Address</th>
                          <th>Contact Number</th>
                          {/* <th>Reviews</th> */}
                          <th>Verified</th>
                          <th>Licence</th>
                          <th>Status</th>
                          <th>Reviews</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allDrivers?.length>0? allDrivers?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <span>{index + lastIndex - limit + 1}</span>
                            </td>
                            <td>
                              <div className="products">
                                <img
                                  src={
                                    item?.profile_image
                                      ? IMG_URL + item?.profile_image
                                      : Placholder
                                  }
                                  className="avatar avatar-md"
                                  alt=""
                                />
                                <div className="pointerUser"
                                  onClick={() =>
                                    navigate(`/driver-detail/${item._id}`)
                                  }
                                >
                                  <h6>{item?.full_name}</h6>
                                  <span>Driver</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <span className="text-primary">
                                {item?.email}
                              </span>
                            </td>
                            <td>
                              <span>{item?.country_code+" "+item?.phone_number}</span>
                            </td>
                            {/* <td>
                              <span>{item?.overall_rating?item?.overall_rating:"N/A"}</span>
                            </td> */}
                            <td>
                            {
                              item?.email_verified==1?( <span className="text-success icon_size">
                                <i className="fa-solid fa-check"></i>
                              </span>):( <span className="text-danger icon_size">
                                <i class="fa-solid fa-xmark"></i>
                              </span>)

                            }
                             
                            </td>

                            <td>
                            {
                              item?.license_number?( <span className="text-success icon_size">
                                <i className="fa-solid fa-check"></i>
                              </span>):( <span className="text-danger icon_size">
                                <i class="fa-solid fa-xmark"></i>
                              </span>)

                            }
                            </td>

                            <td>
                              <span
                                className={`badge light border-0 ${
                                  item?.approval_status === 1
                                    ? "badge-success"
                                    : "badge-danger"
                                } `}
                              >
                                {item?.approval_status  === 1 ? "Approved" :item?.approval_status  === 0? "Pending":"Rejected"}
                              </span>
                            </td>
                            <td>
                             
                              <span>
                              <ReactStars
                                      count={5}
                                      edit={false}
                                      isHalf={true}
                                      value={item?.overall_rating}
                                      size={20}
                                      activeColor="#ffd700"
                                    />
                              {/* {item?.overall_rating?item?.overall_rating:"N/A"} */}
                              </span>
                            
                              {/* <span className="badge light border-0 pl-0">
                                <button onClick={()=>{
                                       item?.license_number?
                                    dispatch(updateDriverProfile({
                                    driverId:item?._id,
                                    approval_status:1
                                   })):toast.warning("Licence info is missing")
                                   
                                }} className="badge border-0 badge-success">
                                  Accept
                                </button>
                              </span>
                              <span className="">
                                <button 
                                onClick={()=>{
                                  item?.license_number ?
                                    dispatch(updateDriverProfile({
                                    driverId:item?._id,
                                    approval_status:2
                                   })):toast.warning("Licence info is missing")
                                   
                                }} 
                                 className="badge border-0 badge-danger">
                                  Reject
                                </button>
                              </span> */}
                            </td>
                          </tr>
                        )):<td colspan="10">
                        <p className="no_content_table">No Drivers yet</p>
                        </td>}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - limit + 1} to{" "}
                        {totalDriver && totalDriver < lastIndex ? totalDriver : lastIndex} of{" "}
                        {totalDriver && totalDriver} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span className="">
                          {number?.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmployeeOffcanvas ref={employe} Title="Add Employee" />
      <InviteCustomer ref={invite} Title="Invite Employee" />
    </Layout>
  );
};

export default Drivers;
