import { START_LOADING, STOP_LOADING } from "../Constant/constant"

const initialState={
    loading:""
}
const loaderReducer=(state = initialState ,action)=>{
    switch(action.type){
        case START_LOADING:
            return{
                ...state,
                loading: action.payload
            }
            case STOP_LOADING:
                return {
                    ...state,
                     loading: action.payload};
            default:
                return state;
    }
}
export default loaderReducer;